import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { IFaq } from 'typings/generated/contentful';

type Props = {
  faqs: IFaq[];
};

export default function BlogFAQ({ faqs }: Props) {
  return (
    <>
      <h2
        className="mt-12 text-3xl font-bold leading-10 tracking-tight text-gray-900"
        id="faq"
      >
        Fragen & Antworten
      </h2>
      <dl
        className="space-y-6 divide-y divide-gray-900/10"
        itemScope
        itemType="https://schema.org/FAQPage"
      >
        {faqs.map((faq) => (
          <Disclosure as="div" className="pt-6" key={faq.fields.question}>
            {({ open }) => (
              <div
                itemProp="mainEntity"
                itemScope
                itemType="https://schema.org/Question"
              >
                <dt>
                  <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-900 focus:outline-none">
                    <h3
                      className="my-0 text-lg font-semibold leading-7"
                      itemProp="name"
                    >
                      {faq.fields.question}
                    </h3>
                    <span className="flex items-center ml-6 h-7">
                      {open ? (
                        <MinusSmallIcon
                          aria-hidden="true"
                          className="w-6 h-6"
                        />
                      ) : (
                        <PlusSmallIcon aria-hidden="true" className="w-6 h-6" />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="pr-12 mt-2">
                  <div
                    itemProp="acceptedAnswer"
                    itemScope
                    itemType="https://schema.org/Answer"
                  >
                    <p className="leading-7 text-gray-600" itemProp="text">
                      {faq.fields.answer}
                    </p>
                  </div>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        ))}
      </dl>
    </>
  );
}
