import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { BLOCKS, Document, Node } from '@contentful/rich-text-types';
import cx from 'classnames';
import GatsbyImage from 'gatsby-image';
import { merge } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import slugify from 'slugify';
import Footer from 'src/components/sections/footer';
import Navbar from 'src/components/sections/navbar';
import BlogFAQ from 'src/components/widgets/FAQ';
import Favicon from 'src/components/widgets/favicon';
import SEO from 'src/components/widgets/seo';
import { useAbsoluteUrl } from 'src/hooks/use-absolute-url';
import { getFluidImage } from 'src/util/get-fluid-image';
import { getSocialButtonData } from 'src/util/social-links';
import { IBlogPost, ILandingPage } from 'typings/generated/contentful';

type Props = {
  location: Location;
  pageContext: {
    landingPage: ILandingPage;
    blogPost: IBlogPost;
  };
};

export default function BlogPage({ location, pageContext }: Props) {
  const absoluteUrl = useAbsoluteUrl();
  const { blogPost: post, landingPage } = pageContext;

  const navbarSection = landingPage.fields.content?.find(
    (section) => section.sys.contentType.sys.id === 'sectionHeader'
  );
  const footerSection = landingPage.fields.content?.find(
    (section) => section.sys.contentType.sys.id === 'sectionFooter'
  );

  const { htmlString, anchors } = convertDocumentToHtmlString(
    post?.fields.content
  );

  const socialLinks = useMemo(
    () =>
      post
        ? getSocialButtonData({
            pageLink: absoluteUrl,
            title: post.fields.title,
          })
        : [],
    [absoluteUrl, post]
  );

  const handleShareClicked = useCallback((link: string) => {
    if (!window?.top) {
      return;
    }

    if (link.startsWith('http')) {
      const h = 500;
      const w = 500;
      const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
      const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
      window.open(
        link,
        '_blank',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
      );
    } else if (link.startsWith('mailto')) {
      window.location.href = link;
    }
  }, []);

  return (
    <div className="flex flex-col text-primary">
      <Favicon icon={landingPage.fields.favicon} />
      <SEO
        canonical={location.href}
        seo={merge({}, landingPage.fields.seoInformation, {
          fields: {
            title: post.fields.seoTitle,
            description: post.fields.seoDescription,
            image:
              post.fields.heroImage?.fields &&
              getFluidImage(post.fields.heroImage),
          },
        })}
      />
      {navbarSection && <Navbar section={navbarSection as any} />}
      {/* header */}
      <div className="px-4 py-12 bg-bg-dark-green">
        <div className="flex flex-col items-center w-full">
          <h1 className="w-full max-w-2xl text-2xl font-bold text-center text-white md:text-3xl">
            {post.fields.title}
          </h1>

          <div className="flex flex-wrap items-center justify-center gap-2 mt-8 italic text-gray-300 md:gap-4 md:flex-row">
            {post.fields.author?.fields.name}
            {post.fields.author && (
              <div className="w-1 h-1 bg-white rounded-full" />
            )}
            {post.sys.createdAt === post.sys.updatedAt
              ? moment(post.sys.createdAt).format('ll')
              : `Aktualisiert am: ${moment(post.sys.updatedAt).format('ll')}`}
          </div>
        </div>
      </div>

      <div className="relative flex flex-col max-w-screen-xl py-8 mx-auto md:flex-row md:px-2 xl:px-0 md:py-16">
        {/* Left sidebar */}
        <div>
          <aside
            className={cx(
              'md:w-240px shrink-0 px-4 md:pl-0 pb-4 md:pr-8 md:border-r border-bg-light-green-2 border-b md:border-b-0 transition-opacity duration-200'
            )}
          >
            <div>
              <div className="mb-4 font-semibold text-green-600">
                INHALTSVERZEICHNIS
              </div>
              <ul className="px-4">
                {anchors.map(({ title, slug }) => (
                  <li className="py-2 list-disc" key={slug}>
                    <a
                      className={cx(
                        'hover:text-orange-600 inline-block text-gray-700 rounded  transition-colors duration-100 align-top'
                      )}
                      href={`#${slug}`}
                    >
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </aside>
        </div>

        {/* Middle area */}
        <div className="w-full px-4 mt-4 md:max-w-250px lg:max-w-500px xl:max-w-800px md:px-8 md:mt-0">
          <article
            className="text-lg rich-text"
            dangerouslySetInnerHTML={{ __html: htmlString }}
          />
          {post?.fields.faQs && <BlogFAQ faqs={post.fields.faQs} />}
        </div>

        {/* Right sidebar */}
        <div>
          <aside
            className={cx(
              'md:w-240px mt-8 md:mt-0 shrink-0 md:pl-8 md:pr-0 px-4 transition-opacity duration-200'
            )}
          >
            {/* avatar */}
            <div className="flex flex-col-reverse w-full gap-12 md:gap-0 md:flex-col">
              {post?.fields.author && (
                <div>
                  <GatsbyImage
                    className="object-cover w-20 border rounded-full aspect-square"
                    draggable={false}
                    fluid={getFluidImage(post.fields.author.fields.avatar)}
                  />
                  <div className="mt-4 text-lg font-bold">
                    {post.fields.author.fields.name}
                  </div>
                  <p className="mt-2 text-gray-700 text-15px">
                    {post.fields.author.fields.bio}
                  </p>
                </div>
              )}
              <div className="flex flex-col gap-2 mt-8 md:grid-cols-1">
                {socialLinks.length > 0 && (
                  <div className="col-span-4 font-bold text-15px">
                    Diesen Artikel teilen
                  </div>
                )}
                <div className="flex gap-2 md:flex-col">
                  {socialLinks.map((item) => (
                    <button
                      className="inline-flex items-center px-4 py-4 text-sm font-medium border border-transparent rounded-md shadow-sm md:py-2 bg-bg-light-green-2 hover:bg-bg-light-green focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:ring-offset-2"
                      key={item.link}
                      onClick={() => handleShareClicked(item.link)}
                      type="button"
                    >
                      <item.icon />
                      <span className="hidden ml-2 md:inline-block">
                        {item.label}
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
      {footerSection && <Footer color="green" section={footerSection as any} />}
    </div>
  );
}

function convertDocumentToHtmlString(document: Document) {
  const anchors: { title: string; slug: string }[] = [];

  const renderAsAnchor = (node, level) => {
    const value = documentToPlainTextString(node);
    const slug = slugify(value).toLowerCase();
    if (level <= 2) {
      anchors.push({
        title: value,
        slug,
      });
    }

    return `<a name="${slug}"></a><h${level}>${value}</h${level}>`;
  };

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node: any) => renderAsAnchor(node, 1),
      [BLOCKS.HEADING_2]: (node: any) => renderAsAnchor(node, 2),
      [BLOCKS.HEADING_3]: (node: any) => renderAsAnchor(node, 3),
      [BLOCKS.HEADING_4]: (node: any) => renderAsAnchor(node, 4),
      [BLOCKS.HEADING_5]: (node: any) => renderAsAnchor(node, 5),
      [BLOCKS.EMBEDDED_ENTRY]: (node: Node) => {
        switch (node.data.target.sys.contentType.sys.id) {
          case 'embed':
            return `<div class="embedded-entry">${node.data.target.fields.code}</div>`;
          default:
            return '';
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
        const { title, description, file } = node.data.target.fields;
        const mimeType = file.contentType;
        const mimeGroup = mimeType.split('/')[0];

        switch (mimeGroup) {
          case 'image':
            return `
            <figure>
              <img
                src="${getFluidImage(node.data.target).src}"
                alt="${description}"
                title="${title}"
              />
              <figcaption>${description || ''}</figcaption>
            </figure>`;
          default:
            return '';
        }
      },
    },
  };

  return {
    htmlString: documentToHtmlString({ ...document }, options),
    anchors,
  };
}
