import React from 'react';

type Props = {
  className?: string;
};

export function Twitter({ className }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_318_132)">
        <path
          d="M13.475 0.00732422H4.51071C2.02696 0.00732422 0 2.03429 0 4.51803V13.4966C0 15.9661 2.02696 17.993 4.51071 17.993H13.4893C15.973 17.993 18 15.9661 18 13.4823V4.51803C17.9857 2.03429 15.9588 0.00732422 13.475 0.00732422ZM13.4607 6.74483C13.4607 6.84476 13.4607 6.94468 13.4607 7.0446C13.4607 10.0422 11.1483 13.4966 6.9088 13.4966C5.60983 13.4966 4.39651 13.1255 3.38303 12.4831C3.5686 12.4974 3.73989 12.5117 3.92546 12.5117C5.01031 12.5117 5.99524 12.1548 6.78033 11.541C5.76685 11.5268 4.92466 10.8701 4.6249 9.97084C4.76764 9.99939 4.91039 10.0137 5.05313 10.0137C5.26725 10.0137 5.48136 9.98512 5.66693 9.92802C4.63918 9.71391 3.85408 8.80035 3.85408 7.70122V7.67267C4.13957 7.84396 4.51071 7.94388 4.88184 7.95816C4.26804 7.55847 3.85408 6.85903 3.85408 6.07394C3.85408 5.65998 3.96828 5.27457 4.16812 4.93199C5.31007 6.30233 6.99445 7.20161 8.90722 7.30154C8.86439 7.13024 8.85012 6.95895 8.85012 6.78766C8.85012 5.53151 9.87787 4.51803 11.1483 4.51803C11.8049 4.51803 12.4044 4.78924 12.8327 5.23175C13.3608 5.13183 13.8462 4.94626 14.2887 4.67505C14.1174 5.2032 13.7462 5.64571 13.2752 5.93119C13.7462 5.8741 14.1887 5.7599 14.6027 5.57433C14.2887 6.03112 13.9033 6.4308 13.4607 6.74483Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_318_132">
          <rect fill="white" height="18" width="18" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function LinkedIn({ className }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.475 0H4.51071C2.02696 0 0 2.02696 0 4.51071V13.4893C0 15.9588 2.02696 17.9857 4.51071 17.9857H13.4893C15.973 17.9857 18 15.9588 18 13.475V4.51071C17.9857 2.02696 15.9588 0 13.475 0ZM5.99524 14.5599H3.42585V7.13719H5.99524V14.5599ZM4.69627 5.99524C3.96828 5.99524 3.38303 5.40999 3.38303 4.682C3.38303 3.954 3.96828 3.36876 4.69627 3.36876C5.42427 3.36876 6.00952 3.954 6.00952 4.682C5.99524 5.40999 5.40999 5.99524 4.69627 5.99524ZM14.5741 14.5599H14.5599H12.4187V10.9627C12.4187 10.092 12.3045 8.97859 11.1197 8.97859C9.90642 8.97859 9.70658 9.9207 9.70658 10.9056V14.5599H7.56542V7.13719H9.56384V8.1364H9.62094C9.93497 7.56542 10.663 7.12292 11.8049 7.12292C14.1602 7.12292 14.5741 8.47898 14.5741 10.4917V14.5599Z"
        fill="black"
      />
    </svg>
  );
}

export function Facebook({ className }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_318_227)">
        <path
          d="M13.4857 0H4.5C2.02857 0 0 2.02857 0 4.51429V13.5C0 15.9714 2.02857 18 4.5 18H13.4857C15.9714 18 18 15.9714 18 13.4857V4.51429C18 2.02857 15.9714 0 13.4857 0ZM11.4143 9H9.71429V14.5714H7.57143V9H6.42857V6.71429H7.42857V5.74286C7.42857 4.82857 7.88571 3.38571 9.81429 3.38571H11.5714V5.28571H10.3286C10.1286 5.28571 9.85714 5.41429 9.85714 5.85714V6.71429H11.6143L11.4143 9Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_318_227">
          <rect fill="white" height="18" width="18" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Mail({ className }: Props) {
  return (
    <svg
      className={className}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="black" height="18" rx="4.5" width="18" />
      <path
        d="M9.32256 9.43981C9.22653 9.51021 9.11325 9.54537 9.00002 9.54537C8.88671 9.54537 8.77347 9.51021 8.67744 9.43981L4.09091 6.07636L3.00004 5.2764L3 12.9999C3.00004 13.3012 3.24422 13.5454 3.54545 13.5454L14.4545 13.5454C14.7558 13.5454 15 13.3011 15 12.9999V5.27637L13.9091 6.07636L9.32256 9.43981Z"
        fill="white"
      />
      <path
        d="M9.00001 8.32367L14.2759 4.45463L3.72403 4.45459L9.00001 8.32367Z"
        fill="white"
      />
    </svg>
  );
}
