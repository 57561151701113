import {
  Facebook,
  LinkedIn,
  Mail,
  Twitter,
} from 'src/components/svg/SocialIcons';

type SocialLinkParams = {
  pageLink: string;
  title: string | undefined;
};

function getFacebookLink({ pageLink }: SocialLinkParams) {
  return `https://www.facebook.com/sharer/sharer.php?u=${pageLink}`;
}

function getTwitterLink({ pageLink, title }: SocialLinkParams) {
  return `https://twitter.com/intent/tweet?url=${pageLink}&text=${title}`;
}

function getLinkedInLink({ pageLink }: SocialLinkParams) {
  return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    pageLink
  )}`;
}

function getEmailLink({ pageLink, title = '' }: SocialLinkParams) {
  return `mailto:?Subject=${encodeURIComponent(
    title
  )}&Body=${encodeURIComponent(pageLink)}`;
}

export function getSocialButtonData(linkParams: SocialLinkParams) {
  return [
    {
      label: 'Twitter',
      icon: Twitter,
      link: getTwitterLink(linkParams),
    },
    {
      label: 'LinkedIn',
      icon: LinkedIn,
      link: getLinkedInLink(linkParams),
    },
    {
      label: 'Facebook',
      icon: Facebook,
      link: getFacebookLink(linkParams),
    },
    {
      label: 'E-Mail',
      icon: Mail,
      link: getEmailLink(linkParams),
    },
  ];
}
